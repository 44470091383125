<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-progress-circular
                :size="100"
                :width="7"
                color="purple"
                indeterminate
                ></v-progress-circular>
            </v-col>
            <v-col cols="12">
                Cargando
            </v-col>
        </v-row>
    </v-container>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
.fill-loader {
    width: 100%;
    height: 100%;
    background-color: rgb(200, 200, 200, 0.7);
    z-index: 10;
}
</style>
<script>
    export default{
        name: 'loader-component',
    }
</script>