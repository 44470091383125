<template>
<!-- panel de control para cruce de datos, usuarios, carruseles registrados, permisos, etc. -->
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        >
        
            <v-card>
                <v-card-title>
                <span class="text-h5">Nueva campaña</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                    <v-row v-if="alertError">
                        <v-col cols="2">

                        </v-col>
                        <v-col cols="8">
                            <v-alert
                            border="top"
                            color="red lighten-2"
                            dark
                            >
                                Datos incompletos
                            </v-alert>
                        </v-col>
                        <v-col cols="2">

                        </v-col>

                    </v-row>
                    <v-row>
                    <v-col cols="12">
                        <v-text-field
                        label="Nombre de la campaña *"
                        v-model="nameCampaign"
                        @keyup="changeName()"
                        required
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-file-input
                        v-model="fileCampaign"
                        accept=".csv"
                        label="Archivo en formato CSV *"
                        required
                        ></v-file-input>
                    </v-col>
                    <!-- SELECT con carruseles disponibles con base en región, area, office 5594000362 -->
                    <v-col cols="12">
                        <v-select
                        @change="selectCarrusel($event)"
                        :items="queueOptions"
                        label="Carrusel *"
                        ></v-select>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                        <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateFormatted"
                                label="Fecha de inicio"
                                hint="MM/DD/YYYY format"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                @blur="date = parseDate(dateFormatted)"
                                v-on="on"
                            ></v-text-field>
                        </template>
                            <v-date-picker
                                v-model="date"
                                no-title
                                :first-day-of-week="1"
                                locale="sp-es"
                                @input="menu1 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">

                        <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="initTime"
                                label="Hora de inicio"
                                @blur="changeNameByDate()"
                                prepend-icon="mdi-clock-time-four-outline"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            v-if="menu2"
                            v-model="initTime"
                            full-width
                            @click:minute="$refs.menu.save(initTime)"
                            ></v-time-picker>
                        </v-menu>
                        
                    </v-col>

                    <!-- End date and time block  -->

                    <v-col cols="12" sm="6" md="6">
                        <v-menu
                            v-model="menu3"
                            :close-on-content-click="true"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFormatted"
                                    label="Fecha de término"
                                    hint="formato: DD/MM/YYYY"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    :readonly=true
                                    v-bind="attrs"
                                    v-on="false"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                no-title
                                v-model="date"
                                locale="sp-es"
                                @input="menu3 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">

                        <v-menu
                        ref="menu4"
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="endTime"
                                label="Hora de término"
                                prepend-icon="mdi-clock-time-four-outline"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            v-if="menu4"
                            v-model="endTime"
                            full-width
                            @click:minute="$refs.menu.save(endTime)"
                            ></v-time-picker>
                        </v-menu>
                        
                    </v-col>
                    

                    </v-row>
                </v-container>
                <small class="red--text">* campos obligatorios</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    text
                    @click="dialog = false"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="saveCampaign"
                >
                    Guardar
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
  import axios from "axios";
  import Campaigns from './Campaigns.vue';
  export default {
    components: {
        Campaigns
    },
    name: 'new-campaign',
    data: vm => ({
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormattedEnd: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        alertError: false,
        queueOptions: ['Corpo MX'],
        queue: null,
        fileCampaign: null,
        dialog: false,
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        initTime: (new Date().getHours()+':'+ new Date().getMinutes()+":"+new Date().getSeconds()),
        endTime: (new Date().getHours()+':'+ new Date().getMinutes()+":"+new Date().getSeconds()),
        nameCampaign: 'Predictivo_',
        nameExtra: ''
      
    }),
    created() {
        this.nameCampaign += this.dateFormatted.toString().replace(/[/]/g,"_")+"_"+this.initTime.replaceAll(':', '_')+'_'
    },
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.date)
      },
    },
    watch: {
      date (val) {
        this.dateFormatted = this.formatDate(this.date)
      },
    },
    methods:{
        show() {
            this.dialog = true
        },
        selectCarrusel(event){
            this.queue = event
        },
        changeName(){
            if(this.nameCampaign.startsWith('Predictivo')){
                this.nameExtra = this.nameCampaign.split('_')[6]
            }
            else{
                this.nameExtra = ''
            }
        },
        changeNameByDate(){
            if(this.nameCampaign.startsWith('Predictivo')){
                this.nameCampaign = 'Predictivo_' + this.dateFormatted.toString().replace(/[/]/g,"_")+"_"+this.initTime.replaceAll(':', '_')+'_'+this.nameExtra
            }
            else{
                this.nameCampaign = this.nameCampaign + this.dateFormatted.toString().replace(/[/]/g,"_")+"_"+this.initTime.replaceAll(':', '_')+'_'
            }
        },
        saveCampaign() {

            if(this.fileCampaign === null || this.nameCampaign === '' || this.queue === null || this.dateFormatted === null){
                this.alertError = true
                console.log('error')
            }
            else{
                if(this.nameCampaign.endsWith('_')){
                    this.nameCampaign = this.nameCampaign.slice(0, -1)
                }
                this.alertError = false
                const formData = new FormData()
                formData.append('file', this.fileCampaign)
                formData.append('name', this.nameCampaign)
                formData.append('queue', this.queue)
                formData.append('initDate', this.reverse(this.dateFormatted.replaceAll('/', '-')) + ' ' + this.initTime)
                formData.append('endDate', this.reverse(this.dateFormattedEnd.replaceAll('/', '-')) + ' ' + this.endTime)
                axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                axios.post("https://predictivo.gadgex.io/apiPredictivo/predictivo.php", formData, {
                    headers: {
                        'Access-Control-Allow-Origin' : '*'
                    }
                }).then((result) => {
                    this.$root.$emit('Campaigns')
                });

                this.dialog = false
            }

        },
        countRowsInFile () {

        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            this.dateFormattedEnd = this.dateFormatted
            this.endDate = date
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        parseDateEnd (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        reverse (str) {
            return str.split("-")[2]+'-'+str.split("-")[1]+'-'+str.split("-")[0];
            // return str.split("").reverse().join("");
        }
    },
    mounted() {
        this.$root.$on('NewCampaign', () => {
            this.show()
        })
    }
  }
</script>