<template>
    <v-container>
        <v-overlay :value="loader">
        <v-row>
            <v-col cols="4"> 
            </v-col>
            <v-col align="center">
                <loader-component v-if="loader"></loader-component>
            </v-col>
            <v-col cols="4"> 
            </v-col>
        </v-row>
        </v-overlay>
        <v-row>
            <v-col align="end">
                <v-btn outlined color="green" @click="createCampaign">
                    Subir campaña
                    <v-icon right dark>
                        mdi-cloud-upload
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="4" md="4">
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            label="Fecha inicio"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    locale="sp-es"
                    v-model="date"
                    @input="menu2 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="endDate"
                            label="Fecha final"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                    locale="sp-es"
                    v-model="endDate"
                    @input="menu3 = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
                <v-btn
                    @click="getCampaigns()"
                    class="mx-2"
                    fab
                    dark
                    
                    color="blue"
                >
                    <v-icon dark>
                        mdi-book-search
                    </v-icon>
                </v-btn>
            <v-col cols="12" sm="4" md="4">
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="calls"
            :items-per-page="5"
            class="elevation-1"
        >
        <template v-slot:item.statusCampaign="{ item }">
            <v-chip
                :color="getColor(item.statusCampaign)"
                dark
            >
                {{ item.statusCampaign == 'finished' ? 'terminada' : item.statusCampaign == 'processing' ? 'procesando' : 'pendiente' }}
            </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
           <v-btn v-if="item.statusCampaign == 'finished'"
                @click="DonwloadReport(item.idCampaign, item.nameCampaign, item.initDate)"
                class="mx-2"
                fab
                dark
                color="blue"
                >
                <v-icon dark>
                    mdi-cloud-download
                </v-icon>
            </v-btn>
        </template>
        </v-data-table>
        <new-campaign></new-campaign>
    </v-container>
</template>

<script>
    import axios from "axios";
    import NewCampaign from './NewCampaign.vue';
    import Loader from './Loader.vue'
    export default {
        components: {
            NewCampaign,
            'loader-component': Loader
        },
        data () {
        return {
            loader: false,
            menu2: false,
            menu3: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            endDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            nuLogin: null,
            modal: false,
            headers: [
            {
                text: 'Campaña ',
                align: 'start',
                sortable: true,
                value: 'nameCampaign',
            },
            { text: 'Fecha', value: 'initDate' },
            // { text: 'Carrusel', value: 'queue' },
            { text: 'Registros', value: 'totalRows' },
            // { text: 'Carrusel', value: 'queue' },
            { text: 'Estatus', value: 'statusCampaign' },
            { text: 'Descargar Reporte', value: 'actions', sortable: false },
            ],
            calls: [
            ],
        }
        },
        mounted(){
            this.$root.$on('Campaigns', () => {
                this.getCampaigns();
            })

            window.nulogin = window.ResuelveLogin({
                domain: 'https://nulogin.resuelve.io',
                area: 'nowhere',
                element: '#login',
                expiration: 8.64e7
            }, (err, token) => {
                // console.log({err, token})
            })

        },
        watch: {
            changeStatus () {
                if(this.calls){
                    this.getCampaigns()
                }
            }
        },
        methods: {
            createCampaign() {
                this.$root.$emit('NewCampaign')
            },
            getColor (status) {
                if (status  == 'finished') return 'green'
                else if (status  == 'processing') return 'blue'
                else return 'orange'
            },
            DonwloadReport(id,name,date){
                this.loader= !this.loader
                const formData = new FormData()
                formData.append('id', id)
                formData.append('name', name)
                formData.append('date', date.split(' ')[0])

                axios.post("https://predictivo.gadgex.io/php/apiPredictivo/query.php", formData, {
                }).then((result) => {
                    this.loader = !this.loader
                    let csv = this.ConvertToCSV(result.data)
                    const anchor = document.createElement('a');
                    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
                    anchor.target = '_blank';
                    anchor.download = name+'.csv';
                    anchor.click();
                }).catch((err) => {
                    this.loader = !this.loader
                    console.error('Error al generar reporte: ', err)
                } )
                
            },
            ConvertToCSV(objArray) {
                var keys = Object.keys(objArray[0]);
                let headers = ''
                keys.forEach(element => headers += element+',');
                headers = headers.substring(0, headers.length - 1);
                var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
                var str = headers+'\r\n';

                for (var i = 0; i < array.length; i++) {
                    var line = '';
                    for (var index in array[i]) {
                        if (line != '') line += ','

                        line += array[i][index];
                    }

                    str += line + '\r\n';
                }

                return str;
            },
            getCampaigns(){

                axios.get("https://predictivo.gadgex.io/apiPredictivo/campaigns.php", {
                params: {
                    initDate: this.date + ' 00:00:00',
                    endDate: this.endDate + ' 23:59:59'
                },
                headers: {
                    // 'Access-Control-Allow-Origin' : '*'
                }
                }).then((result) => {
                    this.calls = result.data
                });
            }

        },
        created() {
            // this.logInWithGoogle()

            this.getCampaigns()
        },
    }
</script>