import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(!_vm.sessionActive)?_c(VMain,{attrs:{"id":"app-login"}},[_c(VContainer,{attrs:{"fill-height":""}},[_c(VBtn,{attrs:{"id":"login","outlined":"","color":"green"}},[_vm._v(" Ingresa "),_c(VIcon,{attrs:{"color":"green darken-2"}},[_vm._v(" mdi-google ")])],1)],1)],1):_vm._e(),(_vm.sessionActive)?_c(VAppBar,{attrs:{"app":"","color":"primary","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c(VImg,{staticClass:"shrink mt-1 hidden-sm-and-down",attrs:{"alt":"RTD","contain":"","min-width":"100","src":"https://resuelvetudeuda.com/wp-content/themes/rtd-2021/img/logo_resuelvetudeuda.svg","width":"100"}})],1),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.logout}},[_c('span',{staticClass:"mr-2"},[_vm._v("Cerrar sesión")]),_c(VIcon,[_vm._v("mdi-open-in-new")])],1)],1):_vm._e(),_c(VSpacer),(_vm.sessionActive)?_c(VMain,[_c('Campaigns')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }