<template>
    <v-app>
        <v-main id="app-login" v-if="!sessionActive">
            <v-container  fill-height>
                <v-btn
                    id="login"
                    outlined
                    color="green"
                    >
                    Ingresa 
                    <v-icon color="green darken-2">
                        mdi-google
                    </v-icon>
                </v-btn>
            </v-container>
        </v-main>
        
        <v-app-bar v-if="sessionActive"
            app
            color="primary"
            dark
        >
            <div class="d-flex align-center">

                <v-img
                alt="RTD"
                class="shrink mt-1 hidden-sm-and-down"
                contain
                min-width="100"
                src="https://resuelvetudeuda.com/wp-content/themes/rtd-2021/img/logo_resuelvetudeuda.svg"
                width="100"
                />
            </div>

            <v-spacer></v-spacer>

            <v-btn
                text
                @click="logout"
            >
                <span class="mr-2">Cerrar sesión</span>
                <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
        </v-app-bar>
        <v-spacer></v-spacer>
    
        <v-main v-if="sessionActive">
        <Campaigns/>
        </v-main>
    </v-app>
</template>
<style >
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
#app-login {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('./assets/background_login.png');
  background-repeat: repeat;
  background-position: center top;
  background-size: 100% auto;
  
}
</style>

<script>
import Campaigns from './components/Campaigns.vue'

export default {
  name: 'App',

  components: {
    Campaigns
  },

  data: () => ({
    agent: null,
    sessionActive: false,
  }),
    mounted(){
        if (window.sessionStorage.token) {
            this.loginSuccess()
        } else {
            this.sessionActive = false
        }
        window.nulogin = window.ResuelveLogin({
            domain: 'https://nulogin.resuelve.io',
            area: 'nowhere',
            element: '#login',
            expiration: 8.64e7,
        }, (err, token) => {
            console.log({err, token})
            if(err){
                console.error('Login error ', err)
            }else{
                this.handleLoginResponse(err, token)
            }
        })
    },
    methods: {
        handleLoginResponse (err, token) {
            if (err) return alert(err)
            window.sessionStorage.token = token
            this.loginSuccess()
        },
        loginSuccess (token) {
            this.sessionActive = true
        },
        logout () {
            this.sessionActive = false
            // window.localStorage.token = false
            // window.sessionStorage.token = false
            delete window.localStorage.token
            delete window.sessionStorage.token
        }
    }
};
</script>
